<template>
    <WorkPlan />
</template>

<script>
import WorkPlan from '@apps/WorkPlan/index.vue'
export default {
    components: {
        WorkPlan
    }
}
</script>