export function dailyItemGenerate(item) {
    return {
        ...item,
        dLoading: false,
        loading: false,
        edited: false,
        oldDescription: item.description,
        task: item.task?.id || null,
        work_type: item.work_type?.code || null,
        duration_fact: (!item.duration_fact || item.duration_fact === '0.00') ? null : Number(item.duration_fact),
        duration_plane: (!item.duration_plane || item.duration_plane === '0.00') ? null : Number(item.duration_plane)
    }
}

export function dailyGenerate(data) {
    return {
        ...data,
        plane_items: data.plane_items.map(item => dailyItemGenerate(item))
    }
}